import React, { forwardRef } from "react";
import { OpenWrapper, Line, MenuTriggerWrapper, ClosedWrapper } from "./styles";

export const MenuTrigger = forwardRef(({ onClick, isOpen }, ref) => {
  return (
    <MenuTriggerWrapper>
      {!isOpen ? (
        <ClosedWrapper ref={ref} onClick={onClick}>
          <Line />
        </ClosedWrapper>
      ) : (
        <OpenWrapper ref={ref} onClick={onClick}>
          <Line />
        </OpenWrapper>
      )}
    </MenuTriggerWrapper>
  );
});
