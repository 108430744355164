import React from "react";
import { Link } from 'gatsby'
import logo from "../../static/img/logo.svg";
import styled from "styled-components";

const StyledNavbar = styled.nav`
  z-index: 120;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: transparent;
  height: 150px;
  width: 100%;
  position: fixed;
  top: 0;
`;
const StyledLogo = styled.div`
  padding-top: 40px;
  padding-left: 70px;
  height: 100px;
  position: fixed;
  top: 0;
  transition: top 0.3s;

  a {
    font-size: 80px;
  }

  img {
    height: 90px;
  }

  @media (max-width: 700px) {
    padding-left: 30px;

    img {
      height: 80px;
    }
  }
`;

const StyledLink = styled(Link)`
  cursor: pointer;
`

export const Navbar = () => {
    React.useEffect(() => {
        let navbarEl = document.getElementById("navbar");
        let prevScrollPos = window.scrollY;

        window.onscroll = function() {
            let currentScrollPos = window.scrollY;
            prevScrollPos > currentScrollPos ? navbarEl.style.top = "0" : navbarEl.style.top = "-200px";
            prevScrollPos = currentScrollPos;
        }
    }, []);
    return (
        <StyledNavbar role="navigation" aria-label="main-navigation">
            <StyledLogo id="navbar">
                <StyledLink to={"/"} title="blok architekci" offset={-200} smooth={true}>
                    <img src={logo} alt="blok architekci logo" />
                </StyledLink>
            </StyledLogo>
        </StyledNavbar>
    );
};