import styled from "styled-components";
import { colors } from "../GlobalStyles";

import bbhlogo from "../../../src/img/blokbimhublogo.svg";
import facebook from "../../../static/img/facebook.svg";
import pinterest from "../../../static/img/pinterest.svg";
import linkedin from "../../../static/img/linkedin.svg";
import instagram from "../../../static/img/instagram.svg";

//Menu Trigger
export const StyledMenuWrapper = styled.div`
  justify-self: end;
  color: ${colors.black};
  z-index: 20000;
`;

export const MenuLogo = styled.div`
  background: url(${bbhlogo}) white round;
  height: 110px;
  width: 250px;
  margin: 0 0 0 0;
  background-repeat: no-repeat;
`;

export const MenuTriggerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  top: 40px;
  right: 70px;
  position: fixed;
  z-index: 20000;
  height: 70px;

  @media (max-width: 700px) {
    right: 40px;
    top: 45px;
  }
`;

export const Language = styled.h4`
  margin: auto 25px 30px 125px;
  cursor: pointer;

  @media (max-width: 920px) {
    margin: 60px 20px 30px 10px;
  }
`;

// export const SmallItemWrapper = styled(MenuItem)`
//   font-size: 18px;
// `;

const GenericWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ClosedWrapper = styled(GenericWrapper)`
  height: 46px;
  width: 70px;
  border-top: 12px solid ${colors.black};
  border-bottom: 12px solid ${colors.black};

  @media (max-width: 700px) {
    height: 36px;
    width: 60px;
    border-top: 12px solid #1B1B1D;
    border-bottom: 12px solid #1B1B1D;
  }
`;

export const OpenWrapper = styled(GenericWrapper)`
  height: 70px;
  width: 70px;

  @media (max-width: 700px) {
    height: 60px;
    width: 60px;
  }
`;

export const Line = styled.div`
  height: 12px;
  background: ${colors.black};
  width: 100%;
`;

// Menu Item
export const MenuHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2px;

  @media (max-width: 700px) {
    margin-top: 12px;
  }
`;

export const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  margin: 0 70px;
  cursor: pointer;

  @media (max-width: 920px) {
    width: 320px;
    margin: 0 50px;
  }
`;

export const ItemWrapper = styled.h3`
  margin-top: 10px;
  color: ${colors.black};

  :hover {
    color: #787878;
  }

  :active {
    color: #2b2b2b;
  }
`;

export const SocialMediaWrapper = styled.div`
  margin: 70px 70px 30px 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 130px;

  @media (max-width: 920px) {
    margin: 60px 20px 30px 50px;
  }
  
  @media (max-width: 700px) {
    width: 100px;
  }
`;

export const Pinterest = styled.a`
  background: url(${pinterest});
  height: 25px;
  width: 22px;
`;

export const Facebook = styled.a`
  background: url(${facebook});
  height: 25px;
  width: 20px;
`;

export const Linkedin = styled.a`
  background: url(${linkedin});
  height: 25px;
  width: 25px;
`;

export const Instagram = styled.a`
  background: url(${instagram});
  height: 25px;
  width: 25px;
  margin-top: 1px;
`;

export const MenuLogoWrapper = styled.a`
  border-top: 4px solid ${colors.black};
  margin: 0 70px;
  padding-top: 25px;
  height: 130px;
  background: white;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  right: 0;
  bottom: 0;
  z-index: 20000;
  position: fixed;
  width: ${({ isOpen }) => (isOpen ? "460px" : 0)};
  
    
  @media (min-width: 920px) {
    ${({ animation }) => animation};
  }
  @media (max-width: 920px) {
    width: ${({ isOpen }) => (isOpen ? "80%" : 0)};
    max-width: 400px;
    margin: 0 50px;
  }

  @media (max-width: 700px) {
    margin: 0 30px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
`;

export const MenuContent = styled.div`
  background-color: white;
  z-index: 20000;
  height: 100%;
  white-space: initial;
`;

export const MenuContainer = styled.div`
  width: ${({ isOpen }) => (isOpen ? "600px" : 0)};
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 920px) {
    ${({ animation }) => animation};
  }
  z-index: 19000;
  height: 110vh;
  @media (max-width: 920px) {
    width: ${({ isOpen }) => (isOpen ? "100%" : 0)};
  }
  position: fixed;
  right: 0;
  top: -10px;
`;
