import React, { useEffect, useRef, useState } from "react";
import { css, keyframes } from "styled-components";
import { MenuTrigger } from "./MenuTrigger";
import { MenuItem } from "./MenuItem";
import {
  MenuContainer,
  SocialMediaWrapper,
  MenuHeader,
  MenuContent,
  MenuLogo,
  MenuItems,
  StyledMenuWrapper,
  ContentWrapper,
  MenuLogoWrapper,
  Language,
  Instagram,
  Linkedin,
  Facebook,
  Pinterest,
} from "./styles";
import useWindowDimensions from "./useWindowDimensions";

const slideInAnimation = keyframes`
 from { width: 0 }
  to { width: 600px }
`;

const slideOutAnimation = keyframes`
  from { width: 600px }
  to { width: 0 }
`;

const slideInLogoAnimation = keyframes`
 from { height: 0; opacity: 0; width: 0  }
  to { height: 130px; opacity: 1; width: 460px  }
`;

const slideOutLogoAnimation = keyframes`
  from { height: 130px; opacity: 1; width: 460px   }
  to { height: 0; opacity: 0; width: 0  }
`;

const useOutsideAlerter = (ref, triggerRef) => {
  const [isOpen, setIsOpen] = useState(null);
  useEffect(() => {
    function handleClickOutside(event) {
      const isTriggerClicked =
        triggerRef?.current && triggerRef?.current.contains(event.target);

      if (
        ref?.current &&
        !ref?.current.contains(event.target) &&
        !isTriggerClicked
      ) {
        isOpen === true && setIsOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, isOpen]);

  return { isOpen, setIsOpen };
};

const texts = {
  projects_pl: "projekty",
  projects_eng: "projects",

  lab_pl: "pracownia",
  lab_eng: "studio",

  offer_pl: "oferta",
  offer_eng: "offer",

  contact_pl: "kontakt",
  contact_eng: "contact",

  clientPanel_pl: "panel klienta",
  clientPanel_eng: "client panel",
};

export const Menu = ({ language, handleLanguageClick }) => {
  const lan = language === "pl" ? "eng" : "pl";
  const wrapperRef = useRef(null);

  const triggerRef = useRef(null);

  const { isOpen, setIsOpen } = useOutsideAlerter(wrapperRef, triggerRef);
  const animation = (status) => {
    if (status) {
      return css`
        animation-name: ${slideInAnimation};
        animation-duration: 1s;
      `;
    } else if (status === false) {
      return css`
        animation-name: ${slideOutAnimation};
        animation-duration: 1s;
      `;
    } else if (status === null) {
      return "";
    }
  };

  const logoAnimation = (status) => {
    if (status) {
      return css`
        animation-name: ${slideInLogoAnimation};
        animation-duration: 1.1s;
      `;
    } else if (status === false) {
      return css`
        animation-name: ${slideOutLogoAnimation};
        animation-duration: 0.8s;
      `;
    } else if (status === null) {
      return "";
    }
  };

  const dimensions = useWindowDimensions();

  const handleClick = () => {
    isOpen === null ? setIsOpen(true) : setIsOpen(!isOpen);
  };

  const handleItemClick = () => {
    dimensions?.width < 920 && setIsOpen(false);
  };

  const translate = (key) => {
    return `${key}_${lan}`;
  };

  return (
    <StyledMenuWrapper id="menu">
      <MenuTrigger ref={triggerRef} isOpen={isOpen} onClick={handleClick} />
      <MenuContainer
        ref={wrapperRef}
        isOpen={isOpen}
        animation={animation(isOpen)}
      >
        <ContentWrapper>
          <MenuContent>
            <MenuHeader>
              <SocialMediaWrapper>
                <Facebook
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/blok.arch"
                ></Facebook>
                <Pinterest
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://pl.pinterest.com/blokarchitekci/"
                ></Pinterest>
                <Instagram
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/blok.architekci/"
                ></Instagram>
                <Linkedin
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.linkedin.com/company/blok-architekci/"
                ></Linkedin>
              </SocialMediaWrapper>
              <Language onClick={handleLanguageClick} isOpen={isOpen}>
                {language}
              </Language>
            </MenuHeader>
            <MenuItems>
              <MenuItem
                onClick={handleItemClick}
                linkTo="/projects"
                label={texts[translate("projects")]}
              />
              <MenuItem
                onClick={handleItemClick}
                linkTo="/lab"
                label={texts[translate("lab")]}
              />
              <MenuItem
                onClick={handleItemClick}
                linkTo="/offer"
                label={texts[translate("offer")]}
              />
              <MenuItem
                onClick={handleItemClick}
                linkTo="/contact"
                label={texts[translate("contact")]}
              />
              <MenuItem
                onClick={handleItemClick}
                linkTo="/client-panel"
                label={texts[translate("clientPanel")]}
              />
            </MenuItems>
          </MenuContent>
          <MenuLogoWrapper
            isOpen={isOpen}
            animation={logoAnimation(isOpen)}
            href="https://blokbimhub.com/"
            title="blok bim hub"
          >
            <MenuLogo />
          </MenuLogoWrapper>
        </ContentWrapper>
      </MenuContainer>
    </StyledMenuWrapper>
  );
};
