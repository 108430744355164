import React from "react";
import { ItemWrapper } from "./styles";
import { Link } from 'gatsby'

import styled from "styled-components";
const StyledLink = styled(Link)`
    text-decoration: none;
      @media (max-width: 1480px) {
        h2 {
          font-size: 70px;
        }
    
        h3 {
          font-size: 30px;
        }
      }
`

export const MenuItem = ({ label, onClick, linkTo, className, children }) => {
  return (
    <StyledLink to={linkTo} smooth={true}>
      <ItemWrapper className={className} onClick={onClick}>
          {children}
        {label}
      </ItemWrapper>
    </StyledLink>
  );
};
